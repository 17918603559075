import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { CustomCookieService } from '../custom-cookie/custom-cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _customCookieService: CustomCookieService) {}

  jwtToken!: string;

  set(token: string): void {
    this.jwtToken = token;
    this._customCookieService.set('auth-token-dev', token);
  }

  get(): string {
    return this._customCookieService.get('auth-token-dev');
  }

  isTokenExpired(): boolean {
    if (this.jwtToken) {
      const decodedToken: { [key: string]: string } = jwtDecode(this.jwtToken);
      const expiryTime: number = Number(decodedToken['exp']);

      return 1000 * expiryTime - new Date().getTime() < 5000;
    } else {
      return true;
    }
  }
}
