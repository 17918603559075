import { Routes } from '@angular/router';
import { authorizeGuard } from '@common/data-access/guard/authorize/authorize.guard';
import { phoneCodeResolver } from '@common/data-access/resolver/phone-code/phone-code.resolver';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
  {
    path: '',
    loadChildren: () =>
      import('./pages/main/main.routes').then((m) => m.mainRoutes),
    canActivate: [authorizeGuard],
  },
  {
    path: 'login',
    pathMatch: 'full',
    loadComponent: () =>
      import('./pages/auth/features/login/login.component').then(
        (c) => c.LoginComponent
      ),
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    loadComponent: () =>
      import(
        './pages/auth/features/forgot-password/forgot-password.component'
      ).then((c) => c.ForgotPasswordComponent),
  },
  {
    path: 'change-password',
    pathMatch: 'full',
    loadComponent: () =>
      import(
        './pages/auth/features/forgot-password/forgot-password.component'
      ).then((c) => c.ForgotPasswordComponent),
  },
  {
    path: 'register',
    pathMatch: 'full',
    loadComponent: () =>
      import('./pages/auth/features/register/register.component').then(
        (c) => c.RegisterComponent
      ),
    resolve: {
      code: phoneCodeResolver,
    },
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/error-page/features/error.routes').then(
        (m) => m.ERROR_ROUTES
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/dashboard',
  },
];
