import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@common/data-access/service/auth/auth.service';
import { PLATFORM_ID, inject } from '@angular/core';
import { StateService } from '@common/data-access/service/state/state.service';
import { isPlatformBrowser } from '@angular/common';

export const authorizeGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const token: string = authService.get();
  const stateService = inject(StateService);
  const platformId = inject(PLATFORM_ID);

  if (token !== null || !authService.isTokenExpired()) {
    return true;
  } else {
    if (isPlatformBrowser(platformId)) {
      router.navigateByUrl('login');
    } else {
      stateService.setLoadingPage(true);
    }

    return false;
  }
};
