import { Injectable } from '@angular/core';
import { Event, RouterEvent, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationTrackerService {
  constructor(public router: Router) {
    router.events
      .pipe(
        filter(
          (e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent
        )
      )
      .subscribe((e: RouterEvent) => {
        this.routerEvents.next(e);
      });
  }

  routerEvents = new BehaviorSubject<RouterEvent | null>(null);
}
